<template>
  <div class="empty-layout">
    <connection-modal />
    <router-view />
  </div>
</template>

<script>
import ConnectionModal from '@/components/modals/ConnectionModal.vue';

export default {
  name: 'EmptyLayout',
  components: {
    ConnectionModal
  }
}
</script>

<style lang="scss">
.empty-layout {
  width: 100%;
}
</style>
