<template>
  <div id="connection-modal" class="uk-flex-top uk-overlay-primary" 
    uk-modal="bg-close: false; esc-close: false"
  >
    <div class="uk-modal-dialog uk-modal-danger uk-modal-body uk-margin-auto-vertical">
      <i uk-icon="icon: bolt; ratio: 4;" class="uk-background-cover uk-light"></i>
      <h2 class="uk-modal-title">{{ $t( 'Connection.Disconnected' ) }}</h2>
      <p>{{ connectionErrorMessage }}</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ConnectionModal',
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      connectionErrorMessage: state => state.venom && state.venom.connectionError
    }),
    connected: {
      get() { return this.$store.state.venom.connected; },
      set(value) { 
        if ( value ) {
          window.UIkit.modal('#connection-modal').hide();
        } else {
          window.UIkit.modal('#connection-modal').show();
        }
        return this.$store.commit('setConnected', { reachable: value }); 
      }
    }
  },
  mounted() {
    window.addEventListener('online', () => { this.connected = navigator.onLine } );
    window.addEventListener('offline', () => { this.connected = navigator.onLine } );
  }
}
</script>
